$position: 4rem;

.card-testimonies {
  width: 100%;
  position: relative;
  // aspect-ratio: 9 / 14;
  border-radius: 1.5rem;
  overflow: hidden;

  @include mq($until: large) {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  @include mq(large) {
    padding: $position;
    aspect-ratio: 16 / 9;
  }

  .cta-video-testimonies {
    @include mq($until: large) {
      top: calc(50% - 8rem);
      right: 2rem;

      .cta-video__label {
        display: none;
      }
    }

    @include mq(large) {
      bottom: $position;
    }
  }
}

.card-testimonies__content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 2.4rem;
  border-radius: 0 0 8px 8px;
  background: rgba(31, 50, 91, 1);

  * {
    color: $white;
  }

  @include mq($until: large) {
    gap: 2rem;
    height: 50%;
  }

  @include mq(large) {
    border-radius: 8px;
    padding: 4rem;
    background: rgba(31, 50, 91, 0.3);
    backdrop-filter: blur(27px);
    position: absolute;
    max-width: 43rem;
    width: 43rem;
    z-index: 2;
    top: $position;
    bottom: $position;
    right: $position;
  }
}

.card-testimonies__content-quote {
  position: relative;
  padding-top: $position / 2;

  &:before {
    @extend %ff-av-next-cond;

    position: absolute;
    content: '“';
    font-size: 3.6rem;
    top: 0;
    line-height: 1;
    left: 0;
  }

  p {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.4rem;
  }

  @include mq(large) {
    padding-top: $position;

    p {
      font-size: 2.4rem;
      line-height: 28px;
    }
  }
}

.card-testimonies__picture {
  object-position: center;
  object-fit: cover;
  width: 100%;

  @include mq($until: large) {
    border-radius: 8px 8px 0 0;
    height: 50%;
  }

  @include mq(large) {
    @include get-all-space;

    height: 100%;
    z-index: 1;
  }
}

.card-testimonies__content-author {
  p,
  span {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
  }

  p {
    margin: 0;
  }
}
